import React from 'react';
import { menuItems } from '../../../common/menu';
import { NavMenuItem } from './NavMenuItem';

export const NavMenu = () => {
	return (
		<nav className="flex h-full">
			<ul
				className="flex justify-between w-full pl-8"
				role="navigation"
			>
				{menuItems
				.filter(item => item.mainNav)
				.map((item, i) => (
					<li
						key={i}
						className={
							`w-full text-center
							${item.to === '/contact' ? 'primary hoverable borderless' : ''}`
						}
					>
						<NavMenuItem
							props={{
								tabIndex: i + 20
							}}
							to={item.to || '/'}
						>
							<span className="relative block">
								{ item.label }
							</span>
						</NavMenuItem>
					</li>
				))}
			</ul>
		</nav>
	);
};
