import React, { FC, ReactNode } from 'react';
import { GatsbyLinkProps, Link } from 'gatsby';

interface NavMenuItemProps extends GatsbyLinkProps<any> {
	noPadding?: boolean,
	external?: boolean,
	props?: { [key: string]: any },
	children?: ReactNode
}

export const NavMenuItem: FC<NavMenuItemProps> =
	(props) => {
	const className = `relative block font-semibold duration-500
		h-full hover:bg-gray-100 flex flex-col justify-center
		cursor-pointer
		${props.noPadding ? '' : 'px-2 py-2 md:py-4'}
		${props.className}`;

	return (
		props.external ? (
			<a
				{...props.props}
				className={className}
				href={props.to}
			>
				<span className="relative block">
					{ props.children }
				</span>
			</a>
		) : (
			<Link
				{...props.props}
				className={className}
				to={props.to}
				role="menuitem"
				activeClassName="bg-gray-100 text-black"
			>
				<span className="relative block">
					{ props.children }
				</span>
			</Link>
		)
	);
};
