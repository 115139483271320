import { PageWrapper } from './src/hooks/page-wrapper';
import './src/css/style.css';
import './src/css/colors.css';
import './src/css/typography.css';
import './src/css/layout.css';
import './src/css/controls.css';
import './src/css/table.css';
import './src/css/forms.css';

export const wrapPageElement = PageWrapper;
