import React, { FC } from 'react';
import { Link } from 'gatsby';
import { menuItems, NavItem } from '../../../common/menu';

export interface MobileNavProps {
	open: boolean
	onClick?: (item: NavItem) => void
}

export const MobileNav: FC<MobileNavProps> = (props) => {
	const handleClick = (item: NavItem) => {
		if(props.onClick) props.onClick(item);
	};

	return (
		<ul className="relative font-bold w-full mt-20">
			{menuItems.map((item, i) => (
				<li key={i}>
					<Link
						tabIndex={i + 40}
						className="block btn primary hoverable text-3xl"
						to={ item.to || '/' }
						onClick={() => handleClick(item)}
					>
						{ item.label }
					</Link>
				</li>
			))}
		</ul>
	);
};
