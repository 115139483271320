import React from 'react';
import { Layout } from '../components/Layout/Layout';
import '../css/style.css';

export const PageWrapper = ({ element, props }) => {
	const { uri } = props;

	return (
		uri === '404' ? (
			element
		) : (
			<Layout
				uri={uri}
				ctx={props.pageContext}
			>
				{ element }
			</Layout>
		)
	);
};
