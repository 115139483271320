import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { MobileNav } from './MobileNav';
import '../../../css/components/mobile-menu.css';

export const MobileMenu = () => {
	const [ open, setOpen ] = useState<boolean>(false);

	const handleToggle = () => {
		setOpen(!open);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<button
				className="btn-burger"
				onClick={handleToggle}
				id="btn-mobile-menu"
				aria-haspopup={true}
				aria-controls="mobile-menu"
				aria-expanded={open}
			>
				<div/>
				<div/>
				<div/>
			</button>
			<div
				className={`mobile-menu fixed shadow-lg bg-white
				left-0 top-0 h-full w-full z-50 duration-500
				bg-c-primary overflow-y-auto ${open ? 'active' : ''}`}
				id="mobile-menu"
				aria-labelledby="btn-mobile-menu"
			>
				<button
					className="absolute block cursor-pointer text-2xl
					leading-none right-0 top-0 p-6 m-1 text-white"
					onClick={handleClose}
				>
					<FontAwesomeIcon icon={faTimes}/>
				</button>
				<MobileNav
					open={open}
					onClick={handleClose}
				/>
			</div>
		</>
	);
};
