import React, { FunctionComponent } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { menuItems } from '../../common/menu';

const year = new Date().getFullYear();

export const Footer: FunctionComponent =
	() => {
	const images = useStaticQuery(graphql`
		query {
			dav: file(relativePath: { eq: "dav-logo.png" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 128) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      americanLegion: file(relativePath: { eq: "american-legion-logo.png" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 128) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      nacvso: file(relativePath: { eq: "nacvso-logo.png" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 128) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      airForce: file(relativePath: { eq: "us-air-force-logo.png" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 128) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      marines: file(relativePath: { eq: "us-marine-corps-logo.png" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 128) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      navy: file(relativePath: { eq: "us-navy-logo.png" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 128) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      army: file(relativePath: { eq: "us-army-logo.png" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 128) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      coastGuard: file(relativePath: { eq: "us-coast-guard-logo.png" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 128) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      spaceForce: file(relativePath: { eq: "us-space-force-logo.png" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 128) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
		}
	`);

	return (
		<footer className="bg-maroon py-10">
			<div className="container mx-auto">
				<div className="flex flex-wrap justify-center gap-4">
					<div>
						<Img
							className="w-24"
							fluid={images.army.childImageSharp.fluid}
							alt="US Army logo"
						/>
					</div>
					<div>
						<Img
							className="w-24"
							fluid={images.marines.childImageSharp.fluid}
							alt="US Marines logo"
						/>
					</div>
					<div>
						<Img
							className="w-24"
							fluid={images.navy.childImageSharp.fluid}
							alt="US Navy logo"
						/>
					</div>
					<div>
						<Img
							className="w-24"
							fluid={images.airForce.childImageSharp.fluid}
							alt="US Air Force logo"
						/>
					</div>
					<div>
						<Img
							className="w-24"
							fluid={images.coastGuard.childImageSharp.fluid}
							alt="US Coast Guard logo"
						/>
					</div>
					<div>
						<Img
							className="w-24"
							fluid={images.spaceForce.childImageSharp.fluid}
							alt="US Space Force logo"
						/>
					</div>
				</div>
				<ul className="muted text-center my-8">
					{menuItems.map((item, i) => (
						<li
							key={i}
							className="inline-block px-4"
						>
							<Link
								className="link"
								to={item.to || '/'}
							>
								{item.label}
							</Link>
						</li>
					))}
				</ul>
				<div className="w-full text-center mt-5">
					<Link
						className="link w-full"
						to="/"
					>
						Veterans Assistance Commission of Kankakee County
						&copy;{ year }
					</Link>
				</div>
				<div className="w-full text-center mt-5">
					Website by&nbsp;
					<a
						className="link"
						href="https://webfoot-designs.com"
						target="__blank"
					>Webfoot Designs</a>
				</div>
			</div>
		</footer>
	);
};
