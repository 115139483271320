export interface NavItem {
	to?: string,
	label?: string,
	mainNav?: boolean
}

export const menuItems: NavItem[] = [
	{
		to: '/financial-assistance',
		label: 'Financial Assistance',
		mainNav: true
	},
	{
		to: '/va-claims',
		label: 'VA Claims',
		mainNav: true
	},
	{
		to: '/treatment-court',
		label: 'Treatment Court',
		mainNav: true
	},
	{
		to: '/discount-program',
		label: 'Discount Program',
		mainNav: true
	},
	{
		to: '/jobs',
		label: 'Job Search',
		mainNav: true
	},
	{
		to: '/faq',
		label: 'FAQ',
		mainNav: true
	},
	{
		to: '/contact',
		label: 'Contact',
		mainNav: true
	},
	{
		to: '/calendar',
		label: 'Calendar',
		mainNav: false
	},
	{
		to: '/meetings',
		label: 'Meetings',
		mainNav: false
	}
];
