import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { NavMenu } from './NavMenu/NavMenu';
import { MobileMenu } from './MobileMenu/MobileMenu';
import { NavMenuItem } from './NavMenu/NavMenuItem';

export const Header = () => {
	const images = useStaticQuery(
		graphql`
			query {
				logo: file(relativePath: { eq: "vackc-logo.png" }) {
		      childImageSharp {
		        fluid(quality: 100, maxWidth: 64) {
		          ...GatsbyImageSharpFluid
		        }
		      }
	      }
			}
		`
	);

  return (
    <header className="md:header w-screen bg-white shadow-md z-10">
	    <div className="w-full bg-gray-100 hidden md:block">
		    <div className="flex flex-row justify-end px-8 lg:container lg:mx-auto">
			    <ul>
				    {[
					    {
					    	href: '#noop',
						    label: '',
						    className: 'hidden'
					    },
					    {
					    	href: '#main-content',
						    label: 'Skip to Content',
						    className: 'skip-to-content'
					    },
							{
								href: '/',
								label: 'Home',
							},
					    {
						    href: 'tel:8159378489',
						    label: (
							    <>
								    <FontAwesomeIcon
									    icon={faPhone}
									    className="mr-2"
								    />
								    815-937-8489
							    </>
						    )
					    },
					    {
						    href: 'mailto:vac@k3county.net',
						    label: (
							    <>
								    <FontAwesomeIcon
									    icon={faEnvelope}
									    className="mr-2"
								    />
								    vac@k3county.net
							    </>
						    )
					    },
					    { to: '/calendar', label: 'Calendar' },
					    { to: '/meetings', label: 'Meetings' },
					    { to: '/staff', label: 'Staff' }
				    ].map((item, i) => (
					    <li
						    key={i}
						    className="inline-block"
					    >
						    {item.to ? (
							    <NavMenuItem
								    props={{
								    	tabIndex: i
								    }}
								    to={item.to}
								    noPadding={true}
								    className={`px-6 py-3 hover:bg-gray-300
								    ${item.className}`}
							    >
								    { item.label }
							    </NavMenuItem>
						    ) : (
							    <NavMenuItem
								    props={{
									    tabIndex: i
								    }}
								    external={true}
								    to={item.href || ''}
								    noPadding={true}
								    className={`px-6 py-3 hover:bg-gray-300
								    ${item.className}`}
							    >
								    { item.label }
							    </NavMenuItem>
						    )}
					    </li>
				    ))}
			    </ul>
		    </div>
	    </div>
	    <div
		    className="flex pl-8 pr-4
		    lg:container lg:mx-auto
		    md:w-full md:px-8"
	    >
		    <div className="flex flex-col justify-center w-16 mr-8">
			    <Link
				    tabIndex={10}
				    className="block py-2"
				    to="/"
			    >
				    <Img
					    fluid={images.logo.childImageSharp.fluid}
					    className="max-w-sm"
					    alt="Veterans Assistance Commission of Kankakee County"
				    />
			    </Link>
		    </div>
		    <div className="flex flex-col justify-center">
			    <h1 className="h3 nm">Veterans Assistance Commission</h1>
			    <h2 className="font-semibold">of Kankakee County</h2>
		    </div>
		    <div className="flex-grow hidden md:block">
			    <NavMenu/>
		    </div>
		    <div className="flex-grow flex justify-end md:hidden">
					<MobileMenu/>
		    </div>
	    </div>
    </header>
  );
}
