import React, { FC, useEffect, useState, useRef, ReactNode } from 'react';
import { usePrevious } from 'react-use';
import { Header } from '../Header/Header';
import { Footer } from '../Footer/Footer';
import { SEOProps } from '../SEO/SEO';
import '@reach/skip-nav/styles.css';

interface LayoutProps extends SEOProps {
	uri: string,
	ctx: any,
	children: ReactNode
}

export const Layout: FC<LayoutProps> = (props) => {
	const mainRef = useRef<HTMLDivElement>(null);
	const prevUri = usePrevious<string>(props.uri || '');
	const [ children, setChildren ] = useState<React.ReactNode>(props.children);
	const [ transState, setTransState ] = useState<boolean>(false);
	const [ didInit, setDidInit ] = useState<boolean>(false);

	useEffect(() => {
		if(didInit && (props.uri !== prevUri)) {
			setTransState(true);
			setTimeout(() => {
				setTransState(false);
				setChildren(props.children);
				if(mainRef.current)
					mainRef.current.scrollTop = 0;
			}, 250);
		}
		setDidInit(true);
	}, [ props.children ]);

	return (
		<div className="flex flex-col min-h-screen font-sans text-gray-900">
			{/*<SEO*/}
			{/*	{...props}*/}
			{/*/>*/}
			<div className="flex flex-col h-screen">
				<div className="z-50">
					<Header/>
				</div>
				<main
					id="main-content"
					style={{
						opacity: transState ? 0 : 1
					}}
					className="flex-1 overflow-auto relative z-0"
					ref={mainRef}
				>
					{ children }
					<Footer/>
				</main>
			</div>
		</div>
	);
}
